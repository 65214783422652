<template>
  <div>
    <div>1、设计目标值记为A，配合比数据库中配合比对应数据记为B；</div>
    <div>2、如数据库或设计目标无材料参数数据，则不得对应参数的分值；</div>
    <div>
      3、当某类材料有两种及以上不同类别或型号的材料时，均分对应参数的分值；（主要指细骨料、粗骨料、外加剂、矿渣粉、粉煤灰、石灰石粉）
    </div>
    <div>
      4、当某种材料得分为0时，说明数据库中配合比没有使用该材料，需对筛选出的配合比进行调整。
    </div>
    <div class="table">
      <el-table
      size="mini"
        :data="tableData"
        border
        style="width: 100%"
        :span-method="objectOneMethod"
      >
        <el-table-column prop="number" label="序号" width="60">
        </el-table-column>
        <el-table-column prop="varieties" label="品种"> </el-table-column>
        <el-table-column prop="parameter" label="参数"> </el-table-column>
        <el-table-column prop="conditions" label="判断条件"> </el-table-column>
        <el-table-column label="权重" width="80">
          <template slot-scope="scope">
            <input type="text" v-model="scope.row.weight" style="width: 50px" />
          </template>
        </el-table-column>
        <el-table-column prop="instructions" label="其他说明">
        </el-table-column>
        <el-table-column label="分值" width="80">
          <template slot-scope="scope">
            <input type="text" v-model="scope.row.score" style="width: 50px" />
          </template>
        </el-table-column>
      </el-table>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      tableData: [
        {
          number: 1,
          varieties: "水泥",
          parameter: "品种等级",
          conditions: "B = A",
          weight: 17,
          instructions: "",
          score: null,
        },
        {
          number: 1,
          varieties: "水泥",
          parameter: "品种等级",
          conditions: "B ≠ A",
          weight: 17,
          instructions: "",
          score: null,
        },
        {
          number: 1,
          varieties: "水泥",
          parameter: "品种等级",
          conditions: "配合比中无该材料",
          weight: 17,
          instructions: "",
          score: null,
        },
        {
          number: 1,
          varieties: "水泥",
          parameter: "28d抗压强度",
          conditions: "B < A，是则得满分",
          weight: 17,
          instructions:
            "如A＞B+5，则设计结果可在数据库中配合比水泥用量基础上-5kg，同时细集料+5kg",
          score: null,
        },
        {
          number: 1,
          varieties: "水泥",
          parameter: "28d抗压强度",
          conditions: "B ≥ A 或无数据，则扣2分",
          weight: 17,
          instructions:
            "如A＞B+5，则设计结果可在数据库中配合比水泥用量基础上-5kg，同时细集料+5kg",
          score: null,
        },
        {
          number: 1,
          varieties: "水泥",
          parameter: "生产厂家",
          conditions: "B = A，得2分，否则不得分",
          weight: 17,
          instructions: "有多种细骨料时，各型号细骨料均分对应分值",
          score: null,
        },

        {
          number: 2,
          varieties: "细骨料",
          parameter: "品种等级",
          conditions: "B = A",
          weight: 10,
          instructions: "有多种细骨料时，各型号细骨料均分对应分值",
          score: null,
        },
        {
          number:2,
          varieties: "细骨料",
          parameter: "品种等级",
          conditions: "B ≠ A",
          weight: 10,
          instructions: "有多种细骨料时，各型号细骨料均分对应分值",
          score: null,
        },
        {
          number: 2,
          varieties: "细骨料",
          parameter: "品种等级",
          conditions: "配合比中无该材料",
          weight: 10,
          instructions: "",
          score: null,
        },
        {
          number: 2,
          varieties: "细骨料",
          parameter: "型号",
          conditions: "B = A，得满分；否则不得分（不满足该条件或无数据）",
          weight: 10,
          instructions:
            "有多种细骨料时，各型号细骨料均分对应分值",
          score: null,
        },
        {
          number: 2,
          varieties: "细骨料",
          parameter: "细度模数",
          conditions: "B ∈ [A±0.8]否则不得分",
          weight: 10,
          instructions:
            "有多种细骨料时，各型号细骨料均分对应分值",
          score: null,
        },
      ],
      spanArr: [],
      pos: 0,
      spanArr1: [],
      pos1: 0,
      spanArr2: [],
      pos2: 0,
    };
  },
  mounted() {
    this.setrowspans();
  },
  methods: {
    setrowspans() {
        for (var i = 0; i < this.tableData.length; i++) {
        if (i === 0) {
          this.spanArr2.push(1);
          this.pos2 = 0;
        } else {
          // 判断当前元素与上一个元素是否相同
          if (
            this.tableData[i].varieties === this.tableData[i - 1].varieties
          ) {
            this.spanArr2[this.pos2] += 1;
            this.spanArr2.push(0);
          } else {
            this.spanArr2.push(1);
            this.pos2 = i;
          }
        }
      }
      this.setrowspans1();
    },
    setrowspans1() {
     for (var i = 0; i < this.tableData.length; i++) {
        if (i === 0) {
          this.spanArr1.push(1);
          this.pos1 = 0;
        } else {
          // 判断当前元素与上一个元素是否相同
          if (
            this.tableData[i].parameter === this.tableData[i - 1].parameter
          ) {
            this.spanArr1[this.pos1] += 1;
            this.spanArr1.push(0);
          } else {
            this.spanArr1.push(1);
            this.pos1 = i;
          }
        }
      }
      this.setrowspans2();
    },
    setrowspans2() {
      for (var i = 0; i < this.tableData.length; i++) {
        if (i === 0) {
          this.spanArr.push(1);
          this.pos = 0;
        } else {
          // 判断当前元素与上一个元素是否相同
          if (
            this.tableData[i].instructions === this.tableData[i - 1].instructions
          ) {
            this.spanArr[this.pos] += 1;
            this.spanArr.push(0);
          } else {
            this.spanArr.push(1);
            this.pos = i;
          }
        }
        console.log(this.spanArr);
      }
    },
    objectOneMethod({ row, column, rowIndex, columnIndex }) {
      const _row = this.spanArr[rowIndex];
      const _row1 = this.spanArr1[rowIndex];
      const _row2 = this.spanArr2[rowIndex];
      if (columnIndex === 0 || columnIndex === 1 || columnIndex === 4) {
        return {
          rowspan:  _row2,
          colspan: 1,
        };
      }
      if (columnIndex === 2) {
        return {
          rowspan:  _row1,
          colspan: 1,
        };
      }
      if (columnIndex === 5) {
        return {
          rowspan: _row,
          colspan: 1,
        };
      }
    },
  },
};
</script>


<style lang="less" scoped>
.table {
  margin-top: 20px;
}
</style>